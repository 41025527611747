import React, { useState } from "react";
import "./styles/css/main.css";
import Fullpage from "./components/Fullpage";
import Mobile from "./components/mobile/Mobile";
import Wrapper from "./components/Wrapper";
import WrapperMobile from "./components/mobile/WrapperMobile";
import Navigation from "./components/Navigation";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

function App() {
   return (
      <div className="app">
         <Navigation />
         {window.innerWidth > 1024 ? <Wrapper /> : <WrapperMobile />}
         {window.innerWidth > 1024 ? <Fullpage /> : <Mobile />}

         {/* <HorizontalScroll /> */}
      </div>
   );
}

export default App;
