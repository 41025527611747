import React, { useEffect, useState } from "react";
import quotes from "../../images/quotes.svg";
import line from "../../images/line_mobile4.svg";
import line2 from "../../images/line_mobile5.svg";
import logo1 from "../../images/ninth_logo1.png";
import logo2 from "../../images/ninth_logo2.png";
import logo3 from "../../images/ninth_logo3.png";
import logo4 from "../../images/ninth_logo4.png";
import logo5 from "../../images/ninth_logo5.png";
import logo6 from "../../images/ninth_logo6.png";
import logo7 from "../../images/ninth_logo7.png";
import logo8 from "../../images/ninth_logo8.png";
import stars from "../../images/stars.png";
import send from "../../images/send.png";
import the_magic from "../../images/the-magic.png";
import contact_lines from "../../images/contact_lines.png";
import { ReactComponent as Zapraszamy } from "../../images/zapraszamy.svg";
import { gsap, Power4 } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import * as api from "../../api.js";
export default function MobileFourth() {
   const [formData, setFormData] = useState({
      name: "",
      email: "",
      phone: "",
      comments: "",
   });
   const sendData = async (e) => {
      e.preventDefault();
      try {
         const response = await api.postFormData(formData);
         console.log(response);
      } catch (error) {
         console.log(error);
      }
   };
   useEffect(() => {
      const tl1 = gsap.timeline({
         scrollTrigger: {
            trigger: ".fourth-text-container__first",
            start: "center 75%",
         },
      });

      tl1.from(".fourth-section-first__text1", 0.75, { x: "-150%", y: "100", ease: Power4 }, "-=0.25")
         .from(".fourth-section-first__text2", 0.75, { x: "-150%", y: "100", ease: Power4 }, "-=0.25")
         .from(".fourth-section-first__text3", 0.75, { x: "-150%", y: "100", ease: Power4 }, "-=0.25")
         .from(".fourth-section-first__text4", 0.75, { x: "-150%", y: "100", ease: Power4 }, "-=0.25")
         .from(".fourth-section-first__text5", 0.75, { x: "-150%", y: "100", ease: Power4 }, "-=0.25")
         .from(".fourth-section-first__text6", 0.75, { x: "-150%", y: "100", ease: Power4 }, "-=0.25");

      const tl2 = gsap.timeline({
         scrollTrigger: {
            trigger: ".fourth-text-container__second",
            start: "center 75%",
         },
      });

      tl2.from(".fourth-section-second__text1", 0.75, { x: "-150%", y: "100", ease: Power4 }, "-=0.25")
         .from(".fourth-section-second__text2", 0.75, { x: "-150%", y: "100", ease: Power4 }, "-=0.25")
         .from(".fourth-section-second__text3", 0.75, { x: "-150%", y: "100", ease: Power4 }, "-=0.25");

      const tl3 = gsap.timeline({
         scrollTrigger: {
            trigger: ".fourth-text-container__third",
            start: "center 75%",
         },
      });

      tl3.from(".fourth-section-third__text1", 0.75, { x: "-150%", y: "100", ease: Power4 }, "-=0.25")
         .from(".fourth-section-third__text2", 0.75, { x: "-150%", y: "100", ease: Power4 }, "-=0.25")
         .from(".fourth-section-third__text3", 0.75, { x: "-150%", y: "100", ease: Power4 }, "-=0.25")
         .from(".fourth-section-third__text4", 0.75, { x: "-150%", y: "100", ease: Power4 }, "-=0.25")
         .from(".fourth-section-third__text5", 0.75, { x: "-150%", y: "100", ease: Power4 }, "-=0.25")
         .from(".fourth-section-third__text6", 0.75, { x: "-150%", y: "100", ease: Power4 }, "-=0.25");

      const tl4 = gsap.timeline({
         scrollTrigger: {
            trigger: ".fourth-text-container__fourth",
            start: "center 75%",
         },
      });

      tl4.from(".fourth-section-fourth__text1", 0.75, { x: "-150%", y: "100", ease: Power4 }, "-=0.25")
         .from(".fourth-section-fourth__text2", 0.75, { x: "-150%", y: "100", ease: Power4 }, "-=0.25")
         .from(".fourth-section-fourth__text3", 0.75, { x: "-150%", y: "100", ease: Power4 }, "-=0.25");
   });

   return (
      <div className="mobileFourth relative">
         <div className="grey-place absolute"></div>
         <img className="absolute line1" src={line} alt="" />
         <div className="banner flex absolute">
            <div className="banner__line1"></div>
            <div className="banner__line2"></div>
            <div className="banner__line3"></div>
         </div>
         <div className=" banner-horizontal flex flex-col absolute">
            <div className="banner-horizontal__line3 w-full"></div>
            <div className="banner-horizontal__line2 w-full"></div>
            <div className="banner-horizontal__line1 w-full"></div>
         </div>

         <img className="quotes quotes-section__first absolute" src={quotes} alt="" />
         <div className="text-container__first fourth-text-container__first text-big absolute text-right">
            <div>
               <p className="fourth-section-first__text1">Everybody loves</p>
            </div>
            <div>
               <p className="fourth-section-first__text2">someone</p>
            </div>
            <div>
               <p className="fourth-section-first__text3">We love</p>
            </div>
            <div>
               <p className="fourth-section-first__text4">stories</p>
            </div>
            <div>
               <p className="fourth-section-first__text5">
                  <span>Come and</span>
               </p>
            </div>
            <div>
               <p className="fourth-section-first__text6">
                  <span>join us!</span>
               </p>
            </div>
         </div>

         <div className="logos-wrapper absolute flex flex-row flex-wrap  items-center justify-center">
            {/* <div>
               <img src={logo1} alt="" />
            </div> */}
            <div>
               <img src={logo2} alt="" />
            </div>
            <div>
               <img src={logo3} alt="" />
            </div>
            <div>
               <img src={logo4} alt="" />
            </div>
            {/* <div>
               <img src={logo5} alt="" />
            </div> */}
            {/* <div>
               <img src={logo6} alt="" />
            </div> */}
            <div>
               <img src={logo7} alt="" />
            </div>
            <div>
               <img src={logo8} alt="" />
            </div>
            <div>
               <img src={logo3} alt="" />
            </div>
            <div>
               <img src={logo4} alt="" />
            </div>
         </div>
         {/* <img className="absolute line2" src={line2} alt="" /> */}
         <div className="grey-place2 absolute"></div>
         {/* <div className="text-container__second text-small absolute text-left fourth-text-container__second" id="tenth">
            <div>
               <p className="fourth-section-second__text1">Pracujemy zwinnie i szybko,</p>
            </div>
            <div>
               <p className="fourth-section-second__text2">w stałej i bliskiej relacji</p>
            </div>
            <div>
               <p className="fourth-section-second__text3">z Klientem.</p>
            </div>
         </div> */}
         {/* <img className="stars absolute" src={stars} alt="" /> */}
         <img className="quotes quotes-section__second absolute" src={quotes} alt="" />

         <Zapraszamy className="zapraszamy-title absolute" />
         <div className="circle absolute"></div>
         <div className="text-container__third text-big absolute text-left fourth-text-container__third">
            <div>
               <p className="flex flex-row gap-3 fourth-section-third__text1">
                  Let
                  <div className="inline img-text-wrapper">
                     <img src={the_magic} alt="" />
                  </div>
               </p>
            </div>
            <div>
               <p className="fourth-section-third__text2">begin when</p>
            </div>
            <div>
               <p className="fourth-section-third__text3">the brief gets in</p>
            </div>
            <div>
               <p className="fourth-section-third__text4">and don’t worry</p>
            </div>
            <div>
               <p className="fourth-section-third__text5">
                  <span> let’s create </span>
               </p>
            </div>
            <div>
               <p className="fourth-section-third__text6">
                  <span>our story!</span>
               </p>
            </div>
         </div>

         <img className="absolute contact-lines" src={contact_lines} alt="" />
         <div className="contact-wrapper absolute">
            <div className="contact-wrapper__contact relative">
               <form onSubmit={sendData}>
                  <div>
                     <input type="text" id="name" name="name" placeholder="imię i nazwisko" value={formData.name} onChange={(e) => setFormData({ ...formData, name: e.target.value })} />
                  </div>
                  <div>
                     <input type="text" id="email" name="email" placeholder="e-mail" value={formData.email} onChange={(e) => setFormData({ ...formData, email: e.target.value })} />
                  </div>
                  <div>
                     <input type="text" id="phone" name="phone" placeholder="telefon " value={formData.phone} onChange={(e) => setFormData({ ...formData, phone: e.target.value })} />
                  </div>
                  <div>
                     <input
                        type="text"
                        id="comments"
                        name="comments"
                        placeholder="treść wiadomości"
                        value={formData.comments}
                        onChange={(e) => setFormData({ ...formData, comments: e.target.value })}
                     />
                  </div>
                  <button className="send-btn">
                     <img src={send} alt="send" />
                  </button>
               </form>
               {/* <div>
                  <p>The Bar sp. z o.o.</p>
               </div>
               <div>
                  <p>ul. Konstruktorska 11 (6 piętro)</p>
               </div>
               <div>
                  <p>+48 605 202 112</p>
               </div>
               <div>
                  <p>hello@thebar.pl</p>
               </div> */}
            </div>
         </div>
      </div>
   );
}
