import React, { useEffect } from "react";
import choco1 from "../../images/2czekoladki_big_2.png";
import choco2 from "../../images/2czekoladki_big_3.png";
import choco3 from "../../images/2czekoladki_big_4.png";
import choco4 from "../../images/2czekoladki_big_5.png";
import { ReactComponent as Mouse } from "../../images/mouse.svg";
import { ReactComponent as Circle } from "../../images/wrapper_circle.svg";
import { ReactComponent as Arrow1 } from "../../images/wrapper_circle-arrow.svg";
import { ReactComponent as Arrow2 } from "../../images/wrapper_circle-arrow.svg";
import { gsap, Power4 } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
export default function WrapperMobile() {
   useEffect(() => {
      const tl1 = gsap.timeline({ repeat: -1 });

      tl1.to(".mobile-wrapper_choco1", 0.65, { opacity: 1, ease: Power4 }, "-=0.40")
         .to(".mobile-wrapper_choco1", 0.4, { opacity: 0, ease: Power4 })
         .to(".mobile-wrapper_choco3", 0.65, { opacity: 1, ease: Power4 }, "-=0.40")
         .to(".mobile-wrapper_choco3", 0.4, { opacity: 0, ease: Power4 })
         .to(".mobile-wrapper_choco2", 0.65, { opacity: 1, ease: Power4 }, "-=0.40")
         .to(".mobile-wrapper_choco2", 0.4, { opacity: 0, ease: Power4 })
         .to(".mobile-wrapper_choco4", 0.65, { opacity: 1, ease: Power4 }, "-=0.40")
         .to(".mobile-wrapper_choco4", 0.4, { opacity: 0, ease: Power4 });

      // const tl2 = gsap.timeline({ repeat: -1, yoyo: true});
      // tl2
      // .to(".choco-wrapper", 0.25, {rotate:360, ease: Power4 })

      gsap.to(".mouse-arrow1", 0.75, { y: -6, repeat: -1, yoyo: true, ease: Power4 });

      gsap.to(".mouse-arrow2", 0.75, { y: 6, repeat: -1, yoyo: true, ease: Power4 });

      gsap.to(".forward-arrow", 0.9, { y: 0.8, size: 0.75, repeat: -1, yoyo: true, ease: Power4 });
   });
   return (
      <div className="fixed w-full flex justify-end items-center py-12 px-8 z-50 bottom-0 wrapper-mobile">
         {/* <div className="choco-wrapper relative">
            <img className="absolute mobile-wrapper_choco1 mobile-wrapper_choco" src={choco1} alt="" />
            <img className="absolute mobile-wrapper_choco2 mobile-wrapper_choco" src={choco2} alt="" />
            <img className="absolute mobile-wrapper_choco3 mobile-wrapper_choco" src={choco3} alt="" />
            <img className="absolute mobile-wrapper_choco4 mobile-wrapper_choco" src={choco4} alt="" />
         </div> */}

         <div className="flex flex-col gap-8 items-center justify-center">
            <div className="relative mouse-wrapper">
               <Arrow1 className="absolute mouse-arrow2" />
               <Arrow2 className="absolute mouse-arrow1" />
               <Mouse className="absolute mouse" />
            </div>

            <div className="relative forward-wrapper">
               <Arrow1 className="absolute forward-arrow" />
               <Circle className="absolute forward-circle" />
            </div>
         </div>
      </div>
   );
}
