import React, { useEffect } from "react";
import brainstorm from "../../images/mobile-brainstorm.png";

import the from "../../images/the.png";
import bar from "../../images/bar.png";
import underline from "../../images/underline.png";
import leg1 from "../../images/mobile-leg1.png";
import leg2 from "../../images/mobile-leg2.png";
import quotes from "../../images/quotes.svg";
import chocolate from "../../images/fourth_chocolate.png";
import brandarea from "../../images/brandarea.png";

import bar1 from "../../images/fourth_bar1.png";
import bar2 from "../../images/fourth_bar2.png";
import bar3 from "../../images/fourth_bar3.png";
import bar4 from "../../images/fourth_bar4.png";

import tetris1 from "../../images/bitbybit_2.svg";
import tetris2 from "../../images/bitbybit_3.svg";
import tetris3 from "../../images/bitbybit_4.svg";
import tetris4 from "../../images/bitbybit_5.svg";
import tetris5 from "../../images/bitbybit_6.svg";
import tetris6 from "../../images/bitbybit_7.svg";
import tetris7 from "../../images/bitbybit_8.svg";
import tetris8 from "../../images/bitbybit_9.svg";
import tetris9 from "../../images/bitbybit_10.svg";
import tetris10 from "../../images/bitbybit_11.svg";
import tetris11 from "../../images/bitbybit_12.svg";
import tetris12 from "../../images/bitbybit_13.svg";
import tetris13 from "../../images/bitbybit_14.svg";
import tetris14 from "../../images/bitbybit_15.svg";
import tetris15 from "../../images/bitbybit_16.svg";

import line from "../../images/line_mobile1.svg";
import { gsap, Power4 } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
export default function MobileFirst() {
   useEffect(() => {
      const tl1 = gsap.timeline({
         scrollTrigger: {
            trigger: ".mobileFirst",
            start: "top 100%",
            end: "bottom bottom",
         },
      });

      tl1.from(".first__underline", 1, { width: 0, ease: Power4 })
         .from(".the-bar", 2, { y: 200, ease: Power4 })
         .from(".first__text1", 0.5, { x: "-150%", y: "100", ease: Power4 }, "-=0.25")
         .from(".first__text2", 0.5, { x: "-150%", y: "100", ease: Power4 }, "-=0.25")
         .from(".first__text3", 0.5, { x: "-150%", y: "100", ease: Power4 }, "-=0.25");

      const tl2 = gsap.timeline({
         scrollTrigger: {
            trigger: ".mobileFirst",
            start: "top 100%",
            end: "bottom bottom",
         },
      });

      tl2.from(".lines-wrapper-first", 2, { width: 0, ease: Power4 }, "-=1")
         .from(".lines-wrapper-second", 2, { width: 0, ease: Power4 }, "-=1")
         .from(".lines-wrapper-third", 2, { width: 0, ease: Power4 }, "-=1");

      const tl3 = gsap.timeline({
         scrollTrigger: {
            trigger: ".text-container__first",
            start: "center 75%",
         },
      });

      tl3.from(".second__text1", 0.5, { x: "-150%", y: "100", ease: Power4 }, "-=0.25")
         .from(".second__text2", 0.5, { x: "-150%", y: "100", ease: Power4 }, "-=0.25")
         .from(".second__text3", 0.5, { x: "-150%", y: "100", ease: Power4 }, "-=0.25")
         .from(".second__text4", 0.5, { x: "-150%", y: "100", ease: Power4 }, "-=0.25");

      const tl4 = gsap.timeline({
         scrollTrigger: {
            trigger: ".mobileFirst-divider1",
            start: "center 75%",
         },
      });

      tl4.from(".mobileFirst-divider1-bar", 2, { x: "150%", ease: Power4 });

      const tl5 = gsap.timeline({
         scrollTrigger: {
            trigger: ".text-container__second",
            start: "center 75%",
         },
      });

      tl5.from(".third__text1", 0.5, { x: "-150%", y: "100", ease: Power4 }, "-=0.25")
         .from(".third__text2", 0.5, { x: "-150%", y: "100", ease: Power4 }, "-=0.25")
         .from(".third__text3", 0.5, { x: "-150%", y: "100", ease: Power4 }, "-=0.25")
         .from(".third__text4", 0.5, { x: "-150%", y: "100", ease: Power4 }, "-=0.25");

      const tl6 = gsap.timeline({
         scrollTrigger: {
            trigger: ".text-container__third",
            start: "center 75%",
         },
      });

      tl6.from(".fourth__text1", 0.5, { x: "-150%", y: "100", ease: Power4 }, "-=0.25")
         .from(".fourth__text2", 0.5, { x: "-150%", y: "100", ease: Power4 }, "-=0.25")
         .from(".fourth__text3", 0.5, { x: "-150%", y: "100", ease: Power4 }, "-=0.25")
         .from(".fourth__text4", 0.5, { x: "-150%", y: "100", ease: Power4 }, "-=0.25");

      const tl7 = gsap.timeline({
         scrollTrigger: {
            trigger: ".mobileFirst-divider2",
            start: "center 75%",
         },
      });

      tl7.from(".mobileFirst-divider2-bar", 2, { x: "-150%", ease: Power4 });

      const tl8 = gsap.timeline({
         scrollTrigger: {
            trigger: ".text-container__fourth",
            start: "center 75%",
         },
      });

      tl8.from(".fifth__text1", 0.5, { x: "-150%", y: "100", ease: Power4 }, "-=0.25")
         .from(".fifth__text2", 0.5, { x: "-150%", y: "100", ease: Power4 }, "-=0.25")
         .from(".fifth__text3", 0.5, { x: "-150%", y: "100", ease: Power4 }, "-=0.25")
         .from(".fifth__text4", 0.5, { x: "-150%", y: "100", ease: Power4 }, "-=0.25");

      const tl9 = gsap.timeline({
         scrollTrigger: {
            trigger: ".text-container__fifth",
            start: "center 75%",
         },
      });

      tl9.from(".sixth__text1", 0.5, { x: "-150%", y: "100", ease: Power4 }, "-=0.25")
         .from(".sixth__text2", 0.5, { x: "-150%", y: "100", ease: Power4 }, "-=0.25")
         .from(".sixth__text3", 0.5, { x: "-150%", y: "100", ease: Power4 }, "-=0.25")
         .from(".sixth__text4", 0.5, { x: "-150%", y: "100", ease: Power4 }, "-=0.25");

      const chocoTl = gsap.timeline({
         scrollTrigger: {
            trigger: ".tetris",
            start: "center 75%",
         },
      });

      // chocoTl
      //    .from(".choco-wrapper__bar1", 1, { x: "-260%", y: "140%", ease: Power4 }, "-=0.5")
      //    .from(".choco-wrapper__bar2", 1, { x: "-210%", y: "140%", ease: Power4 }, "-=0.5")
      //    .from(".choco-wrapper__bar3", 1, { x: "-240%", y: "270%", ease: Power4 }, "-=0.5")
      //    .from(".choco-wrapper__bar4", 1, { x: "-280%", y: "390%", ease: Power4 }, "-=0.5");

      chocoTl
         .from(".tetris11", 1.5, { y: -2000, ease: Power4 }, "-=1.3")
         .from(".tetris15", 1.5, { y: -2000, ease: Power4 }, "-=1.3")

         .from(".tetris1", 1.5, { y: -2000, ease: Power4 }, "-=1.3")
         .from(".tetris7", 1.5, { y: -2000, ease: Power4 }, "-=1.3")

         .from(".tetris2", 1.5, { y: -2000, ease: Power4 }, "-=1.3")
         .from(".tetris12", 1.5, { y: -2000, ease: Power4 }, "-=1.3")
         .from(".tetris10", 1.5, { y: -2000, ease: Power4 }, "-=1.3")
         .from(".tetris9", 1.5, { y: -2000, ease: Power4 }, "-=1.3")
         .from(".tetris13", 1.5, { y: -2000, ease: Power4 }, "-=1.3")
         .from(".tetris4", 1.5, { y: -2000, ease: Power4 }, "-=1.3")
         .from(".tetris6", 1.5, { y: -2000, ease: Power4 }, "-=1.3")

         .from(".tetris3", 1.5, { y: -2000, ease: Power4 }, "-=1.3")
         .from(".tetris14", 1.5, { y: -2000, ease: Power4 }, "-=1.3")
         .from(".tetris8", 1.5, { y: -2000, ease: Power4 }, "-=1.3")

         .from(".tetris5", 1.5, { y: -2000, ease: Power4 }, "-=1.3")
         .from(".tetris16", 1.5, { y: -2000, ease: Power4 }, "-=1.3");

      const tl10 = gsap.timeline({
         scrollTrigger: {
            trigger: ".text-container__sixth",
            start: "center 75%",
         },
      });

      tl10
         .from(".seventh__text1", 0.5, { x: "-150%", y: "100", ease: Power4 }, "-=0.25")
         .from(".seventh__text2", 0.5, { x: "-150%", y: "100", ease: Power4 }, "-=0.25")
         .from(".seventh__text3", 0.5, { x: "-150%", y: "100", ease: Power4 }, "-=0.25")
         .from(".seventh__text4", 0.5, { x: "-150%", y: "100", ease: Power4 }, "-=0.25")
         .from(".seventh__text5", 0.5, { x: "-150%", y: "100", ease: Power4 }, "-=0.25")
         .from(".seventh__text6", 0.5, { x: "-150%", y: "100", ease: Power4 }, "-=0.25");

      const tl11 = gsap.timeline({
         scrollTrigger: {
            trigger: ".text-container__seventh",
            start: "center 75%",
         },
      });

      tl11
         .from(".eighth__text1", 0.5, { x: "-150%", y: "100", ease: Power4 }, "-=0.25")
         .from(".eighth__text2", 0.5, { x: "-150%", y: "100", ease: Power4 }, "-=0.25")
         .from(".eighth__text3", 0.5, { x: "-150%", y: "100", ease: Power4 }, "-=0.25")
         .from(".eighth__text4", 0.5, { x: "-150%", y: "100", ease: Power4 }, "-=0.25");
   });

   return (
      <div className="mobileFirst w-full relative">
         <img className="first-line absolute" src={line} alt="" />
         <div className="first-section relative" id="first">
            <div className="flex the-bar-container absolute overflow-hidden flex-col  items-center justify-center">
               <div className="flex pb-5 the-bar flex-row  justify-between items-center overflow-hidden">
                  <div className="img-wrapper overflow-hidden">
                     <img className="first__image" src={brandarea} alt="brandarea" />
                  </div>
                  {/* <div className="img-wrapper overflow-hidden">
                     <img className="first__image" src={the} alt="" />
                  </div>
                  <div className="img-wrapper overflow-hidden">
                     <img className="first__image" src={bar} alt="" />
                  </div> */}
               </div>
               {/* <img className="first__underline" src={underline} alt="" /> */}
            </div>
            <div className=" flex flex-col items-center justify-center lines-wrapper absolute">
               <div className="first-line lines-wrapper-first"></div>
               <div className="second-line lines-wrapper-second"></div>
               <div className="third-line lines-wrapper-third"></div>
            </div>

            <div className="text-container__header text-header absolute text-right">
               <div>
                  <p className="first__text1">enjoy</p>
               </div>
               <div>
                  <p className="first__text2">creativity</p>
               </div>
               <div>
                  <p className="first__text3">together</p>
               </div>
            </div>

            <div className="absolute check-us">
               <p>sprawdź nas ></p>
            </div>
         </div>
         <div className="text-container__first text-small absolute text-right" id="second">
            <div>
               <p className="second__text1">W Brand Area spotykają się otwarci</p>
            </div>
            <div>
               <p className="second__text2">ludzie o wszechstronnych</p>
            </div>
            <div>
               <p className="second__text3">kompetencjach, fantastycznych</p>
            </div>
            <div>
               <p className="second__text4">skillach i wyobraźni.</p>
            </div>
         </div>
         <div className="overflow-hidden mobileFirst-divider1 absolute">
            <div className="mobileFirst-divider1-bar"></div>
         </div>
         <img className="brainstorm absolute" src={brainstorm} alt="brainstorm" />
         <img className="quotes quotes-section1-first absolute" src={quotes} alt="" />
         <div className="text-container__second text-big absolute text-left">
            <div>
               <p className="third__text1">
                  <span>Ladies & Lads</span>
               </p>
            </div>
            <div>
               <p className="third__text2">We open our minds</p>
            </div>
            <div>
               <p className="third__text3">So everyone can tell</p>
            </div>
            <div>
               <p className="third__text4">
                  If <span>The Ide</span> a suits well
               </p>
            </div>
         </div>

         <div className="text-container__third text-small absolute text-right">
            <div>
               <p className="fourth__text1">W The Bar spotykają się otwarci</p>
            </div>
            <div>
               <p className="fourth__text2">ludzie o wszechstronnych</p>
            </div>
            <div>
               <p className="fourth__text3">kompetencjach, fantastycznych</p>
            </div>
            <div>
               <p className="fourth__text4">skillach i wyobraźni.</p>
            </div>
         </div>

         <div className="overflow-hidden mobileFirst-divider2 absolute">
            <div className="mobileFirst-divider2-bar"></div>
         </div>

         <img className="legs-leg1 absolute" src={leg1} alt="" />
         <img className="legs-leg2 absolute" src={leg2} alt="" />

         <img className="quotes quotes-section1-second absolute" src={quotes} alt="" />
         <div className="text-container__fourth text-big absolute text-left" id="third">
            <div>
               <p className="fifth__text1">And as we talk</p>
            </div>
            <div>
               <p className="fifth__text2">
                  <span>The Effects</span> are
               </p>
            </div>
            <div>
               <p className="fifth__text3">coming, just let</p>
            </div>
            <div>
               <p className="fifth__text4">
                  them <span>walk</span>
               </p>
            </div>
         </div>

         <div className="text-container__fifth text-small absolute text-left" id="fourth">
            <div>
               <p className="sixth__text1">Jedno wiemy na pewno:</p>
            </div>
            <div>
               <p className="sixth__text2">na początku potrzebny</p>
            </div>
            <div>
               <p className="sixth__text3">jest pomysł, i kto</p>
            </div>
            <div>
               <p className="sixth__text4">musi go mieć!</p>
            </div>
         </div>

         <div className="overflow-hidden mobileFirst-divider3 absolute">
            <div className="mobileFirst-divider3-bar"></div>
         </div>

         <img className="quotes quotes-section1-third absolute" src={quotes} alt="" />
         <div className="text-container__sixth text-big absolute text-left">
            <div>
               <p className="seventh__text1">Enjoy creativity</p>
            </div>
            <div>
               <p className="seventh__text2">together</p>
            </div>
            <div>
               <p className="seventh__text3">
                  <span>BIT BY BIT,</span>
               </p>
            </div>
            <div>
               <p className="seventh__text4">we are</p>
            </div>
            <div>
               <p className="seventh__text5">talented</p>
            </div>
            <div>
               <p className="seventh__text6">
                  <span>we feel THE BEAT</span>
               </p>
            </div>
         </div>

         <div className="tetris absolute">
            <div className="relative w-full h-full">
               <img className="tetris1 absolute" src={tetris1} alt="" />
               <img className="tetris2 absolute" src={tetris2} alt="" />
               <img className="tetris3 absolute" src={tetris3} alt="" />
               <img className="tetris4 absolute" src={tetris4} alt="" />
               <img className="tetris5 absolute" src={tetris5} alt="" />
               <img className="tetris6 absolute" src={tetris6} alt="" />
               <img className="tetris7 absolute" src={tetris7} alt="" />
               <img className="tetris8 absolute" src={tetris8} alt="" />
               <img className="tetris9 absolute" src={tetris9} alt="" />
               <img className="tetris10 absolute" src={tetris10} alt="" />
               <img className="tetris11 absolute" src={tetris11} alt="" />
               <img className="tetris12 absolute" src={tetris12} alt="" />
               <img className="tetris13 absolute" src={tetris13} alt="" />
               <img className="tetris14 absolute" src={tetris14} alt="" />
               <img className="tetris15 absolute" src={tetris15} alt="" />
            </div>
         </div>

         {/* <div className="text-container__seventh text-small absolute text-right" id="fifth">
            <div>
               <p className=".eighth__text1">Z pracą jest jak z pudełkiem</p>
            </div>
            <div>
               <p className=".eighth__text2">klocków, można budować</p>
            </div>
            <div>
               <p className=".eighth__text3">statki kosmiczne i pałace</p>
            </div>
            <div>
               <p className=".eighth__text4">dla księżniczek osobno.</p>
            </div>
         </div> */}
      </div>
   );
}
