import React, { useEffect } from "react";
import chocolate from "../images/chocolate.png";
import ig from "../images/ig-logo.png";
import fb from "../images/fb-logo.png";
import choco1 from "../images/2czekoladki_big_2.png";
import choco2 from "../images/2czekoladki_big_3.png";
import choco3 from "../images/2czekoladki_big_4.png";
import choco4 from "../images/2czekoladki_big_5.png";
import { ReactComponent as Mouse } from "../images/mouse.svg";
import { ReactComponent as Circle } from "../images/wrapper_circle.svg";
import { ReactComponent as Arrow1 } from "../images/wrapper_circle-arrow.svg";
import { ReactComponent as Arrow2 } from "../images/wrapper_circle-arrow.svg";
import { gsap, Power4 } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
export default function Wrapper() {
   useEffect(() => {
      const tl1 = gsap.timeline({ repeat: -1 });

      tl1.to(".mobile-wrapper_choco1", 0.65, { opacity: 1, ease: Power4 }, "-=0.40")
         .to(".mobile-wrapper_choco1", 0.4, { opacity: 0, ease: Power4 })
         .to(".mobile-wrapper_choco3", 0.65, { opacity: 1, ease: Power4 }, "-=0.40")
         .to(".mobile-wrapper_choco3", 0.4, { opacity: 0, ease: Power4 })
         .to(".mobile-wrapper_choco2", 0.65, { opacity: 1, ease: Power4 }, "-=0.40")
         .to(".mobile-wrapper_choco2", 0.4, { opacity: 0, ease: Power4 })
         .to(".mobile-wrapper_choco4", 0.65, { opacity: 1, ease: Power4 }, "-=0.40")
         .to(".mobile-wrapper_choco4", 0.4, { opacity: 0, ease: Power4 });

      gsap.to(".mouse-arrow1", 0.75, { y: -6, repeat: -1, yoyo: true, ease: Power4 });

      gsap.to(".mouse-arrow2", 0.75, { y: 6, repeat: -1, yoyo: true, ease: Power4 });

      gsap.to(".forward-arrow", 0.9, { x: 0.8, size: 0.75, repeat: -1, yoyo: true, ease: Power4 });
   });

   const sections = gsap.utils.toArray(".slide");

   let maxWidth = 0;

   const getMaxWidth = () => {
      maxWidth = 0;
      sections.forEach((section) => {
         maxWidth += section.offsetWidth;
      });
   };

   getMaxWidth();

   const forwardHandler = () => {
      gsap.to(sections, { x: () => `-${maxWidth - window.innerWidth}` });
   };
   return (
      <div className="wrapper fixed w-full flex justify-end items-center pt-12 pb-4 px-24 z-50 bottom-0">
         {/* <div className="choco-wrapper relative">
            <img className="absolute mobile-wrapper_choco1 mobile-wrapper_choco" src={choco1} alt="" />
            <img className="absolute mobile-wrapper_choco2 mobile-wrapper_choco" src={choco2} alt="" />
            <img className="absolute mobile-wrapper_choco3 mobile-wrapper_choco" src={choco3} alt="" />
            <img className="absolute mobile-wrapper_choco4 mobile-wrapper_choco" src={choco4} alt="" />
         </div> */}
         <div className="flex flex-row gap-12 items-center justify-center">
            {/* <div className="flex flex-row gap-8 items-center justify-center">
               <img src={ig} alt="" />
               <img src={fb} alt="" />
            </div> */}

            <div className="relative mouse-wrapper">
               <Arrow1 className="absolute mouse-arrow2" />
               <Arrow2 className="absolute mouse-arrow1" />
               <Mouse className="absolute mouse" />
            </div>

            <div className="relative forward-wrapper" onClick={() => forwardHandler()}>
               <Arrow1 className="absolute forward-arrow" />
               <Circle className="absolute forward-circle" />
            </div>
         </div>
      </div>
   );
}
