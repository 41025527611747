import React, { useEffect } from "react";
import line from "../images/line2.svg";
import drawing from "../images/second_image.png";
import quotes from "../images/quotes.svg";
import { gsap, Power4 } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
export default function Second() {
   gsap.registerPlugin(ScrollTrigger);
   useEffect(() => {
      const tl1 = gsap.timeline({
         scrollTrigger: {
            trigger: ".small-text-wrapper ",
            start: () => "+=" + window.innerWidth * 0.2,
         },
      });

      tl1.from(".second__small-text1", 1, { x: "-150%", y: "100", ease: Power4 }, "-=0.5")
         .from(".second__small-text2", 1, { x: "-150%", y: "100", ease: Power4 }, "-=0.5")
         .from(".second__small-text3", 1, { x: "-150%", y: "100", ease: Power4 }, "-=0.5")
         .from(".second__small-text4", 1, { x: "-150%", y: "100", ease: Power4 }, "-=0.5");

      const tl2 = gsap.timeline({
         scrollTrigger: {
            trigger: ".second",
            start: () => "+=" + window.innerWidth * 0.8,
         },
      });

      tl2.from(".second__bar", 2, { width: 0, ease: Power4 }, "-=1");

      const tl3 = gsap.timeline({
         scrollTrigger: {
            trigger: ".second",
            start: () => "+=" + window.innerWidth * 0.8,
         },
      });

      tl3.from(".second__big-text1", 1, { y: 200, ease: Power4 }, "-=0.5")
         .from(".second__big-text2", 1, { y: 200, ease: Power4 }, "-=0.5")
         .from(".second__big-text3", 1, { y: 200, ease: Power4 }, "-=0.5")
         .from(".second__big-text4", 1, { y: 200, ease: Power4 }, "-=0.5");
   }, []);
   return (
      <div className="second slide relative" id="second">
         <div className="bar second__bar"></div>

         <img className="line absolute" src={line} />

         <div className="quotes-wrapper absolute">
            <img className="quotes " src={quotes} />
         </div>

         <div className="w-full absolute drawing-wrapper">
            <img src={drawing} />
         </div>
         <div className="big-text-wrapper absolute flex flex-col items-start overflow-hidden justify-center">
            <div className="overflow-hidden">
               <p className="second__big-text1 overflow-hidden ">
                  <span>Ladies & Lads</span>
               </p>
            </div>
            <div className="overflow-hidden">
               <p className="second__big-text2 overflow-hidden ">We open our minds</p>
            </div>
            <div className="overflow-hidden">
               <p className="second__big-text3 overflow-hidden ">So everyone can tell</p>
            </div>
            <div className="overflow-hidden">
               <p className="second__big-text4 overflow-hidden ">
                  If <span>The Idea</span> suits well
               </p>
            </div>
         </div>
         <div className="small-text-wrapper overflow-hidden absolute flex flex-col items-start justify-center">
            <p className="second__small-text1">W Brand Area spotykają się</p>
            <p className="second__small-text2">otwarci ludzie o wszechstronnych</p>
            <p className="second__small-text3">kompetencjach, fantastycznych skillach</p>
            <p className="second__small-text4">i wyobraźni.</p>
         </div>
      </div>
   );
}
