import React from "react";
import MobileFirst from "./MobileFirst";
import MobileSecond from "./MobileSecond";
import MobileThird from "./MobileThird";
import MobileFourth from "./MobileFourth";
import line from "../../images/mobileLine1.png";
export default function Mobile() {
   return (
      <section id="panels">
         <div className="mobile relative" id="panels-container">
            <MobileFirst />
            {/* <MobileSecond /> */}
            <div className="grey-divider"></div>
            <MobileThird />
            <MobileFourth />
         </div>
      </section>
   );
}
