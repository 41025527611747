import React, { useEffect, useState, useRef } from "react";
import quotes from "../../images/quotes.svg";
import line1 from "../../images/line_mobile2.svg";
import line2 from "../../images/line_mobile3.svg";
import portfolio from "../../images/portfolio.svg";
import choco from "../../images/choco_portfolio.svg";
import okovita1 from "../../images/portfolio1_okovita.jpg";
import okovita2 from "../../images/portfolio2_okovita.jpg";
import okovita3 from "../../images/portfolio3_okovita.jpg";
import okovita4 from "../../images/portfolio4_okovita.jpg";
import mercedes1 from "../../images/portfolio5_mercedes.jpg";
import dentons1 from "../../images/portfolio6_dentons.jpg";
import youstethics1 from "../../images/portfolio7_youstethics.jpg";
import basecamp1 from "../../images/portfolio8_basecamp.jpg";
import basecamp2 from "../../images/portfolio9_basecamp.jpg";
import basecamp3 from "../../images/portfolio10_basecamp.jpg";
import basecamp4 from "../../images/portfolio11_basecamp.jpg";

import basecampvid1 from "../../images/basecamp.mp4";
import basecampvid2 from "../../images/basecamp2.mp4";
import basecampvid3 from "../../images/basecamp3.mp4";
import chmura from "../../images/chmura.mp4";
import allenvid from "../../images/allen.mp4";
//logos

import logo from "../../images/eighth_logo.png";
import frseLogo from "../../images/frse-logo.png";
import baseLogo from "../../images/base-logo.png";
import youthLogo from "../../images/youth-logo.png";
import mercLogo from "../../images/merc-logo.png";
import dentosLogo from "../../images/dentos-logo.png";
import chmuraLogo from "../../images/chmura-logo.png";
import allenLogo from "../../images/allen-logo.png";
import canonLogo from "../../images/canon-logo.png";

import allen1 from "../../images/portfolio12_allen.jpg";
import canon1 from "../../images/portfolio13_canon.jpg";
import frse1 from "../../images/portfolio14_frse.jpg";
import { gsap, Power4 } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { CSSTransition } from "react-transition-group";
import { Swiper, SwiperSlide } from "swiper/react";
import { BrowserRouter as Router, Route, Switch, NavLink } from "react-router-dom";
// Import Swiper styles

import "swiper/swiper-bundle.min.css";

// swiper core styles

// modules styles

// import Swiper core and required modules
import SwiperCore, { Navigation } from "swiper";

// install Swiper modules
SwiperCore.use([Navigation]);
export default function MobileThird() {
   const [activeMenu, setActiveMenu] = useState(false);
   const handleClick = () => {
      setActiveMenu(!activeMenu);
   };
   const [active, setActive] = useState(true);
   const video1 = useRef(null);
   const video2 = useRef(null);
   const video3 = useRef(null);
   const video4 = useRef(null);
   const video5 = useRef(null);
   const pauseVid = (i) => {
      if (i === "1") {
         video1.current.pause();
         video2.current.pause();
      }

      if (i === "2") {
         video3.current.pause();
      }

      if (i === "3") {
         video4.current.pause();
      }

      if (i === "4") {
         video5.current.pause();
      }
   };

   useEffect(() => {
      const tl1 = gsap.timeline({
         scrollTrigger: {
            trigger: ".third-section-text1",
            start: "center 75%",
         },
      });

      tl1.from(".third-section-first__text1", 1, { x: "-150%", y: "100", ease: Power4 }, "-=0.25").from(".third-section-first__text2", 1, { x: "-150%", y: "100", ease: Power4 }, "-=0.25");

      const tl2 = gsap.timeline({
         scrollTrigger: {
            trigger: ".third-section-lines",
            start: "center 75%",
         },
      });

      tl2.from(".third-first-line", 2, { width: 0, ease: Power4 }, "-=1")
         .from(".third-second-line", 2, { width: 0, ease: Power4 }, "-=1")
         .from(".third-third-line", 2, { width: 0, ease: Power4 }, "-=1");

      const tl3 = gsap.timeline({
         scrollTrigger: {
            trigger: ".third-section-text3",
            start: "center 75%",
         },
      });

      tl3.from(".third-section-third__text1", 1, { x: "-150%", y: "100", ease: Power4 }, "-=0.25")
         .from(".third-section-third__text2", 1, { x: "-150%", y: "100", ease: Power4 }, "-=0.25")
         .from(".third-section-third__text3", 1, { x: "-150%", y: "100", ease: Power4 }, "-=0.25")
         .from(".third-section-third__text4", 1, { x: "-150%", y: "100", ease: Power4 }, "-=0.25");
   });

   return (
      <div className="mobileThird relative">
         <div className="first-section w-full"></div>
         <div className="second-section w-full"></div>
         <img className="first-line absolute" src={line1} alt="" />
         <img className="second-line absolute" src={line2} alt="" />
         <img className="quotes quotes-section3__first absolute" src={quotes} alt="" />
         <div className="text-container__first third-section-text1 text-big absolute text-left" id="sixth">
            <div>
               <p className="third-section-first__text1">
                  let’s check our <span>skills</span>
               </p>
            </div>
            <div>
               <p className="third-section-first__text2">
                  and find out what people <span>feels</span>
               </p>
            </div>
         </div>

         <div className="text-container__second text-medium absolute text-right">
            <div>
               <p>design / branding /</p>
            </div>
            <div>
               <p>strategia komunikacji /</p>
            </div>
            <div>
               <p>digital / social media /</p>
            </div>
            <div>
               <p>video</p>
            </div>
         </div>

         <img className="portfolio absolute" src={portfolio} alt="" />
         <img className="choco absolute" src={choco} alt="" />
         <div className="third-section-lines absolute">
            <div className=" third-first-line"></div>
            <div className="third-second-line"></div>
            <div className="third-third-line"></div>
         </div>

         <div className="text-container__third third-section-text3 text-big absolute text-center" id="seventh">
            <div>
               <p className="third-section-third__text1">Created</p>
            </div>
            <div>
               <p className="third-section-third__text2">together,</p>
            </div>
            <div>
               <p className="third-section-third__text3">
                  <span>with</span>
               </p>
            </div>
            <div>
               <p className="third-section-third__text4">
                  <span>pleasure</span>
               </p>
            </div>
         </div>
         {/* <div className="menu-btn" onClick={() => handleClick()}></div> */}
      </div>
   );
}
