import React, { useEffect, useState } from "react";
import thebar from "../images/logo_thebar.png";
import brandarea from "../images/brandarea.png";
import underline from "../images/logo_underline.png";
// import { ReactComponent as Circle } from "../images/menu-circle.png";
import circle from "../images/menu-circle.png";
import { gsap, Power4 } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import close from "./../images/close-ico.png";
export default function Wrapper() {
   gsap.registerPlugin(ScrollToPlugin, ScrollTrigger);
   const [menuActive, setMenuActive] = useState(true);
   console.log(menuActive);
   useEffect(() => {
      let panelsSection = document.querySelector("#panels"),
         panelsContainer = document.querySelector("#panels-container"),
         tween;
      const panels = gsap.utils.toArray(".slide");
      document.querySelectorAll(".anchor").forEach((anchor) => {
         anchor.addEventListener("click", function (e) {
            e.preventDefault();
            let targetElem = document.querySelector(e.target.getAttribute("href")),
               y = targetElem;
            if (targetElem && panelsContainer.isSameNode(targetElem.parentElement)) {
               let totalScroll = tween.scrollTrigger.end - tween.scrollTrigger.start,
                  totalMovement = (panels.length - 1) * targetElem.offsetWidth;
               y = Math.round(tween.scrollTrigger.start + (targetElem.offsetLeft / totalMovement) * totalScroll);
            }
            gsap.to(window, {
               scrollTo: {
                  y: y,
                  autoKill: false,
               },
               duration: 1,
            });
         });
      });

      const sections = gsap.utils.toArray(".slide");
      let maxWidth = 0;

      const getMaxWidth = () => {
         maxWidth = 0;
         sections.forEach((section) => {
            maxWidth += section.offsetWidth;
         });
      };

      getMaxWidth();
      console.log(maxWidth);

      ScrollTrigger.addEventListener("refreshInit", getMaxWidth);

      tween = gsap.to(sections, {
         x: () => `-${maxWidth - window.innerWidth}`,
         ease: "none",
         scrollTrigger: {
            trigger: ".parent",
            pin: true,
            scrub: true,
            end: () => `+=${maxWidth}`,
            invalidateOnRefresh: true,
         },
      });
      const tl1 = gsap.timeline({
         scrollTrigger: {
            trigger: ".second",
            toggleActions: "restart none reverse none",
            start: () => "+=" + window.innerWidth * 0.2,
         },
      });

      tl1.from(".navigation_underline", 1, { opacity: 0, ease: Power4 }, "-=0.5").from(".navigation_thebar", 1, { y: "100", ease: Power4 }, "-=0.5");

      const tl2 = gsap.timeline({
         scrollTrigger: {
            trigger: ".first",
         },
      });

      tl2.from(".menu-circle", 2, {
         opacity: 0,
         ease: Power4,
         stagger: {
            grid: [3, 3],
            from: "center",
            amount: 1,
         },
      })
         .from(".circles-container", 2, { rotate: "520", ease: Power4 }, "-=2")
         .from(".menu-section__number", 0.5, { y: "100", ease: Power4 }, "-=0.2")
         .from(".menu-section__title", 0.5, { x: "100", ease: Power4 }, "-=0.5");

      const text1 = gsap.timeline({
         scrollTrigger: {
            trigger: ".second ",
            toggleActions: "restart reverse restart reset",
            end: () => "+=" + window.innerWidth * 1,
         },
      });
      text1.from(".text-container1", 1, { x: "-100%", ease: Power4 });

      const text2 = gsap.timeline({
         scrollTrigger: {
            trigger: ".second ",
            toggleActions: "restart reverse restart reset",
            start: () => "+=" + window.innerWidth * 1,
            end: () => "+=" + window.innerWidth * 2,
         },
      });

      text2.to(".text-container1", 0.3, { opacity: 0, ease: Power4 }).from(".text-container2", 1, { x: "-100%", ease: Power4 });

      const text3 = gsap.timeline({
         scrollTrigger: {
            trigger: ".third ",
            toggleActions: "restart reverse restart reset",
            start: () => "+=" + window.innerWidth * 2,
            end: () => "+=" + window.innerWidth * 3,
         },
      });

      text3.to(".text-container2", 0.3, { opacity: 0, ease: Power4 }).from(".text-container3", 1, { x: "-100%", ease: Power4 });

      const text4 = gsap.timeline({
         scrollTrigger: {
            trigger: ".fourth ",
            toggleActions: "restart reverse restart reset",
            start: () => "+=" + window.innerWidth * 3,
            end: () => "+=" + window.innerWidth * 4,
         },
      });

      text4.to(".text-container3", 0.3, { opacity: 0, ease: Power4 }).from(".text-container4", 1, { x: "-100%", ease: Power4 });

      const text5 = gsap.timeline({
         scrollTrigger: {
            trigger: ".fifth ",
            toggleActions: "restart reverse restart reset",
            start: () => "+=" + window.innerWidth * 4,
            end: () => "+=" + window.innerWidth * 5,
         },
      });

      text5.to(".text-container4", 0.3, { opacity: 0, ease: Power4 }).from(".text-container5", 1, { x: "-100%", ease: Power4 });

      const text6 = gsap.timeline({
         scrollTrigger: {
            trigger: ".sixth ",
            toggleActions: "restart reverse restart reset",
            start: () => "+=" + window.innerWidth * 5,
            end: () => "+=" + window.innerWidth * 6,
         },
      });

      text6.to(".text-container5", 0.3, { opacity: 0, ease: Power4 }).from(".text-container6", 1, { x: "-100%", ease: Power4 });

      const text7 = gsap.timeline({
         scrollTrigger: {
            trigger: ".seventh ",
            toggleActions: "restart reverse restart reset",
            start: () => "+=" + window.innerWidth * 6,
            end: () => "+=" + window.innerWidth * 7,
         },
      });

      text7.to(".text-container6", 0.3, { opacity: 0, ease: Power4 }).from(".text-container7", 1, { x: "-100%", ease: Power4 });

      const text8 = gsap.timeline({
         scrollTrigger: {
            trigger: ".eighth ",
            toggleActions: "restart reverse restart reset",
            start: () => "+=" + window.innerWidth * 9,
            end: () => "+=" + window.innerWidth * 10,
         },
      });

      text8.to(".text-container7", 0.3, { opacity: 0, ease: Power4 }).from(".text-container8", 1, { x: "-100%", ease: Power4 });

      const text9 = gsap.timeline({
         scrollTrigger: {
            trigger: ".ninth ",
            toggleActions: "restart reverse restart reset",
            start: () => "+=" + window.innerWidth * 10,
            end: () => "+=" + window.innerWidth * 11,
         },
      });

      text9.to(".text-container8", 0.3, { opacity: 0, ease: Power4 }).from(".text-container9", 1, { x: "-100%", ease: Power4 });
   });
   return (
      <div className="navigation fixed w-full flex justify-between items-center py-4 px-8 z-50 top-0">
         {menuActive ? (
            <div className="main-menu absolute px-8 pt-5 flex flex-col gap-5">
               <div className="flex justify-between">
                  <div className="text-container">
                     <a href="#first" class="anchor">
                        brandarea.pl
                     </a>
                  </div>

                  <div
                     className="close-btn"
                     onClick={() => {
                        gsap.to(".main-menu", 0, { display: "none" });
                     }}
                  >
                     <img src={close} />
                  </div>
               </div>
               <div className="text-container">
                  <a
                     href="#second"
                     class="anchor"
                     onClick={() => {
                        gsap.to(".main-menu", 0, { display: "none" });
                     }}
                  >
                     Kompetencje
                  </a>
               </div>
               <div className="text-container">
                  <a
                     href="#third"
                     class="anchor"
                     onClick={() => {
                        gsap.to(".main-menu", 0, { display: "none" });
                     }}
                  >
                     efekt
                  </a>
               </div>
               {/* <div className="text-container">
                  <a
                     href="#fourth"
                     class="anchor"
                     onClick={() => {
                        gsap.to(".main-menu", 0, { display: "none" });
                     }}
                  >
                     bit by bit
                  </a>
               </div> */}
               {/* <div className="text-container">
                  <a
                     href="#fifth"
                     class="anchor"
                     onClick={() => {
                        gsap.to(".main-menu", 0, { display: "none" });
                     }}
                  >
                     team
                  </a>
               </div> */}
               <div className="text-container">
                  <a
                     href="#sixth"
                     class="anchor"
                     onClick={() => {
                        gsap.to(".main-menu", 0, { display: "none" });
                     }}
                  >
                     skills & experience
                  </a>
               </div>
               <div className="text-container">
                  <a
                     href="#seventh"
                     class="anchor"
                     onClick={() => {
                        gsap.to(".main-menu", 0, { display: "none" });
                     }}
                  >
                     portfolio
                  </a>
               </div>
               {/* <div className="text-container">
                  <a
                     href="#tenth"
                     class="anchor"
                     onClick={() => {
                        gsap.to(".main-menu", 0, { display: "none" });
                     }}
                  >
                     the magic
                  </a>
               </div> */}
               <div className="text-container">
                  <a
                     href="#eleventh"
                     class="anchor"
                     onClick={() => {
                        gsap.to(".main-menu", 0, { display: "none" });
                     }}
                  >
                     kontakt
                  </a>
               </div>
            </div>
         ) : (
            <></>
         )}
         <div className="flex gap-8 items-center justify-center">
            <div
               className="circles-container flex flex-col gap-2"
               onClick={() => {
                  gsap.set(".main-menu", { display: "block" });
                  gsap.from(".main-menu", 1, { x: "-100%" });
               }}
            >
               <div className="flex flex-row gap-2 justify-between items-center">
                  <img src={circle} className="menu-circle medium" alt="" />
                  <img src={circle} className="menu-circle big" alt="" />
                  <img src={circle} className="menu-circle medium" alt="" />
                  {/* <Circle className="menu-circle medium" />
                  <Circle className="menu-circle big" />
                  <Circle className="menu-circle medium" /> */}
               </div>
               <div className="flex flex-row gap-2 justify-between items-center">
                  <img src={circle} className="menu-circle big" alt="" />
                  <img src={circle} className="menu-circle small" alt="" />
                  <img src={circle} className="menu-circle big" alt="" />
                  {/* <Circle className="menu-circle big" />
                  <Circle className="menu-circle small" />
                  <Circle className="menu-circle big" /> */}
               </div>

               <div className="flex flex-row gap-2 justify-between items-center">
                  <img src={circle} className="menu-circle medium" alt="" />
                  <img src={circle} className="menu-circle big" alt="" />
                  <img src={circle} className="menu-circle medium" alt="" />
                  {/* <Circle className="menu-circle medium" />
                  <Circle className="menu-circle big" />
                  <Circle className="menu-circle medium" /> */}
               </div>
            </div>
            {/* <div className="overflow-hidden flex items-center justify-center relative text-wrapper">
               <div className="text-container1 gap-1 overflow-hidden navigation-text-container absolute flex flex-row items-center justify-center">
                  <p className="navigation-text-container__number whitespace-nowrap">01 //</p> <p className="navigation-text-container__title "> brandstories.pl</p>{" "}
               </div>

               <div className="text-container2 gap-1 overflow-hidden navigation-text-container absolute flex flex-row items-center justify-center">
                  <p className="navigation-text-container__number ">02 //</p> <p className="navigation-text-container__title "> Kompetencje</p>{" "}
               </div>

               <div className="text-container3 gap-1 overflow-hidden navigation-text-container absolute flex flex-row items-center justify-center">
                  <p className="navigation-text-container__number ">03 // </p> <p className="navigation-text-container__title "> efekt</p>{" "}
               </div>

               <div className="text-container4 gap-1 overflow-hidden navigation-text-container absolute flex flex-row items-center justify-center">
                  <p className="navigation-text-container__number ">04 // </p> <p className="navigation-text-container__title "> bit by bit</p>{" "}
               </div>

               <div className="text-container5 gap-1 overflow-hidden navigation-text-container absolute flex flex-row items-center justify-center">
                  <p className="navigation-text-container__number ">05 // </p> <p className="navigation-text-container__title "> team</p>{" "}
               </div>

               <div className="text-container6 gap-1 overflow-hidden navigation-text-container absolute flex flex-row items-center justify-center">
                  <p className="navigation-text-container__number ">06 // </p> <p className="navigation-text-container__title "> skills & experience</p>{" "}
               </div>

               <div className="text-container7 gap-1 overflow-hidden navigation-text-container absolute flex flex-row items-center justify-center">
                  <p className="navigation-text-container__number ">07 // </p> <p className="navigation-text-container__title "> portfolio</p>{" "}
               </div>

               <div className="text-container8 gap-1 overflow-hidden navigation-text-container absolute flex  flex-row items-center justify-center">
                  <p className="navigation-text-container__number ">8 // </p> <p className="navigation-text-container__title "> the magic</p>{" "}
               </div>

               <div className="text-container9 gap-1 overflow-hidden navigation-text-container absolute flex flex-row items-center justify-center">
                  <p className="navigation-text-container__number ">9 // </p> <p className="navigation-text-container__title "> kontakt</p>{" "}
               </div>
            </div> */}
         </div>

         <div className="flex flex-col items-center gap-1 justify-center navigation-logo">
            <div className="overflow-hidden">
               <img src={brandarea} className="mb-2 navigation_thebar" alt="thebar" />
            </div>

            {/* <img src={underline} className="navigation_underline" alt="underline" /> */}
         </div>
      </div>
   );
}
